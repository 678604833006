import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nos services | Services au Paintball Les Lacs Adrénaline
			</title>
			<meta name={"description"} content={"Les Lacs Adrénaline Paintball Arena offre une gamme de services conçus pour fournir une expérience de paintball exaltante à tous les joueurs."} />
			<meta property={"og:title"} content={"Nos services | Services au Paintball Les Lacs Adrénaline"} />
			<meta property={"og:description"} content={"Les Lacs Adrénaline Paintball Arena offre une gamme de services conçus pour fournir une expérience de paintball exaltante à tous les joueurs."} />
			<meta property={"og:image"} content={"https://frenchwinselect.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frenchwinselect.com/img/2513220.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<meta name={"msapplication-TileImage"} content={"https://frenchwinselect.com/img/2513220.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Text
				margin="0px 0px 70px 0px"
				font="normal 600 46px/1.2 --fontFamily-sans"
				md-margin="0px 0px 50px 0px"
				text-align="center"
				width="100%"
			>
				Nos services
			</Text>
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				align-items="center"
				width="50%"
				lg-width="100%"
			>
				<Image
					src="https://frenchwinselect.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="360px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Les Lacs Adrénaline Paintball Arena offre une gamme de services conçus pour fournir une expérience de paintball exaltante à tous les joueurs. Des tireurs novices aux vétérans du paintball tactique, nous répondons à tous les niveaux d'enthousiasme avec un éventail d'options de jeu engageantes et des installations de premier ordre.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Reviews-3">
			<Text margin="0px 0px 70px 0px" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center" md-margin="0px 0px 50px 0px">
				What customers say about us
			</Text>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 30px"
				justify-content="center"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-grid-gap="30px"
			>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Champs de bataille dynamiques
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Variété d'arènes thématiques : découvrez le combat dans de multiples environnements, des forêts denses aux paysages urbains, chacun avec ses défis et ses tactiques uniques.
							<br />
							<br />
							- Des jeux en constante évolution : Nos scénarios sont régulièrement mis à jour afin d'offrir des expériences nouvelles et passionnantes, et de tenir en haleine les joueurs les plus expérimentés.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Equipement et matériel
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Marqueurs de paintball à la pointe de la technologie : Utilisez nos marqueurs de haute qualité, entretenus pour assurer la précision et la fiabilité du jeu.
							<br />
							<br />
							- Équipement de protection complet : La sécurité avant tout - masques complets, gilets pare-balles et tenues de camouflage pour vous protéger et vous cacher dans le feu de l'action.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Événements de groupe et d'entreprise
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Séances de renforcement de l'esprit d'équipe : Forgez des liens et développez l'esprit d'équipe grâce à nos forfaits spécialement conçus pour les entreprises.
							<br />
							<br />
							- Soirées privées : Célébrez les anniversaires, les enterrements de vie de garçon ou toute autre occasion spéciale avec une séance de paintball palpitante.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Formation et ateliers
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Orientation des nouveaux joueurs : Séances d'introduction pour les nouveaux joueurs afin d'apprendre les bases du paintball, les règles de sécurité et l'étiquette du jeu.
							<br />
							<br />
							- Entraînement tactique avancé : Améliorez vos compétences grâce à des ateliers axés sur la stratégie, l'adresse au tir et le travail d'équipe.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Installations et commodités
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Pro Shop sur place : Équipez-vous avec les derniers accessoires, vêtements et équipements de paintball disponibles à l'achat ou à la location.
							<br />
							<br />
							- Zones de repos et de récupération : Détendez-vous et ressourcez-vous dans nos aires de repos confortables où des rafraîchissements sont disponibles pour vous permettre de faire le plein d'énergie.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px">
						<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans">
							Engagement de la communauté
						</Text>
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
							- Tournois réguliers : Participez à nos ligues et à nos tournois dans les arènes pour avoir une chance de gagner des prix et de remporter des titres dont vous pourrez vous vanter.
							<br />
							<br />
							{" "}- Les jeunes et les débutants sont les bienvenus : nous encourageons la croissance du sport avec des programmes et des réductions pour les jeunes joueurs et les débutants.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});